import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import Number from 'ui/atoms/number';
import BigNumber from 'bignumber.js';
import { WalletType } from 'ui/types/wallet';
import { Money } from 'ui/types/money';
import Currency from 'ui/atoms/currency';
import { toMoney, toNumber } from 'ui/helper/money';
import Accordion from 'ui/atoms/accordion';
import { NetworkTypeEnum, TokensApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import makeLink from 'helper/make-link';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import { makeModalForm } from 'src/ui/molecules/modal';
import Checkbox from 'ui/atoms/checkbox';
import * as Styled from './styled';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';

export interface DepotTransferMaskFields {
  to: string;
  amount: string;
  isPlatformWallet: string;
  invitationLink?: string;
}

export interface DepotTransferInputMaskProps {
  /** Additional classes. */
  className?: string;

  onSubmit?: (values: DepotTransferMaskFields) => void;

  maxAmount?: BigNumber;

  walletType: WalletType;

  networkType: NetworkTypeEnum;

  backToBackMinPrice?: Money;

  tokenId: string;

  amount?: string;

  isPlatformWallet: string;

  onChangeConfirmWallet: (isConfirmWalletSelected: boolean) => void;

  whiteListedError?: string;
}

export const DepotTransferInputMaskForm = makeModalForm<DepotTransferMaskFields>();

const DepotTransferInputMask: FunctionComponent<DepotTransferInputMaskProps> = (props) => {
  const {
    maxAmount,
    networkType,
    backToBackMinPrice,
    amount,
    isPlatformWallet,
    onChangeConfirmWallet,
    tokenId,
    whiteListedError,
  } = props;

  const [invitationLinkExpanded, setInvitationLinkExpanded] = useState(false);
  const [isConfirmWalletSelected, setConfirmWalletSelected] = useState(false);

  const { investor } = useInvestorSelector();

  const [invitationLink, setInvitationLink] = useState('');

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const tokensApi: TokensApi = useMemo(() => makeAuthenticatedApi(TokensApi), [makeAuthenticatedApi]);

  /*const balanceError: { balance: string } | null = error.getErrorCode(
      "transfer_insufficient_balance"
    );
    if (balanceError) {
      maxAmount = makeBigInt(balanceError.balance);
      handled = true;
    }
  }*/

  useEffect(() => {
    withApi(async () => {
      const transferInvitationId = await tokensApi.tokensTransferInvitationCreate({
        id: tokenId,
        transferInvitationCreateRequest: { distributionPlatform: investor?.distributionPlatformId || '' },
      });
      const invitationLink = makeLink(
        INVESTMENT_ROUTES.campaign,
        {
          campaignId: transferInvitationId.id,
        },
        true,
      );
      setInvitationLink(invitationLink);
    });
  }, [withApi, tokensApi]);

  useEffect(() => {
    setConfirmWalletSelected(false);
  }, [isPlatformWallet]);

  const onChangeConfirmWalletCheckbox = useCallback(() => {
    onChangeConfirmWallet(!isConfirmWalletSelected);
    setConfirmWalletSelected(!isConfirmWalletSelected);
  }, [isConfirmWalletSelected]);

  return (
    <DepotTransferInputMaskForm.I18nKey i18nKey="depotTransferInputMask">
      {/* error.hasErrorCode("transfer_invalid_wallet") */}
      <DepotTransferInputMaskForm.Group
        fieldError={whiteListedError || <Translate name="portfolio.invalidWallet" />}
        name="to"
        required={true}
      >
        {networkType === NetworkTypeEnum.ETHEREUM && DepotTransferInputMaskForm.Validators.EthereumAddress()}
        {networkType === NetworkTypeEnum.ETHEREUM && DepotTransferInputMaskForm.Validators.TokenAddressChecksum()}
        {networkType === NetworkTypeEnum.STELLAR && DepotTransferInputMaskForm.Validators.StellarAddress()}
        <DepotTransferInputMaskForm.Input />
      </DepotTransferInputMaskForm.Group>
      {/* error.hasErrorCode("transfer_insufficient_balance"),  */}
      <DepotTransferInputMaskForm.Group name="amount" required={true}>
        {DepotTransferInputMaskForm.Validators.Integer()}
        {DepotTransferInputMaskForm.Validators.Range(
          1,
          maxAmount,
          undefined,
          maxAmount && (
            <>
              <Translate
                name="portfolio.transfer.amountDescription"
                as="div"
                args={[
                  <Number key={0} type="shares">
                    {maxAmount}
                  </Number>,
                ]}
              />
              {amount && (
                <Translate
                  name="portfolio.transfer.amountDescriptionDetailed"
                  as="div"
                  args={[
                    <Currency>
                      {toMoney(toNumber(backToBackMinPrice) * parseInt(amount), backToBackMinPrice?.currency)}
                    </Currency>,
                  ]}
                />
              )}
            </>
          ),
        )}
        <DepotTransferInputMaskForm.Input type="number" min={1} max={maxAmount?.toNumber()} step={1} />
      </DepotTransferInputMaskForm.Group>

      <DepotTransferInputMaskForm.Group name="isPlatformWallet">
        <DepotTransferInputMaskForm.GroupToggle>
          {['true', 'false'].map((option, index) => (
            <DepotTransferInputMaskForm.Radio value={option} key={index}>
              <Translate name={`commonOptions.${option}`} />
            </DepotTransferInputMaskForm.Radio>
          ))}
        </DepotTransferInputMaskForm.GroupToggle>
      </DepotTransferInputMaskForm.Group>

      {isPlatformWallet === 'true' ? (
        <Accordion
          label={<Translate name="portfolio.transfer.noAccountYet" as="span" />}
          expanded={invitationLinkExpanded}
          onChange={() => setInvitationLinkExpanded(!invitationLinkExpanded)}
        >
          <Styled.InputTransfer>
            <CopyInputGroup valueToCopy={invitationLink} disabled />
          </Styled.InputTransfer>
          <small>
            <Translate name="portfolio.transfer.invitationLinkDescription" />
          </small>
        </Accordion>
      ) : (
        <>
          <Checkbox checked={isConfirmWalletSelected} onChange={onChangeConfirmWalletCheckbox}>
            <Translate name="portfolio.transfer.confirmOwningWallet" />
          </Checkbox>
          <small>
            <Translate name="portfolio.transfer.ownerWalletDescription" />
          </small>
        </>
      )}

      <DepotTransferInputMaskForm.GenericErrorMessages />
    </DepotTransferInputMaskForm.I18nKey>
  );
};

export default DepotTransferInputMask;
